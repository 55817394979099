/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, type JSX } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { Icon } from '../icon/icon'
import Text from '~/components/text/text'
import { useOnOutsideClick } from '~/hooks/useOnOutsideClick'
import { cn } from '~/utils/misc'

interface ModalProps {
	onClose: () => void
	title?: string
	children: JSX.Element
	color?: 'green' | 'red' | 'orange' | 'dark' | 'background'
	size?: 'md' | 'lg' | 'xl'
	position?: 'center' | 'top'
}

export default function Modal({
	onClose,
	title,
	children,
	color = 'background',
	size = 'md',
	position = 'center',
}: ModalProps) {
	const ref = useRef<HTMLDivElement | null>(null)
	const bgColor = () => {
		switch (color) {
			case 'green':
				return 'bg-green-900'
			case 'orange':
				return 'bg-orange-900'
			case 'red':
				return 'bg-red-900'
			case 'dark':
				return 'bg-bgTo'
			case 'background':
				return 'bg-background'
			default:
				return 'bg-background'
		}
	}
	useOnOutsideClick(ref, () => onClose())
	const { t } = useTranslation()
	const modalRoot = document?.getElementById('modal-root')
	if (!modalRoot) {
		throw new Error('Modal root element not found')
	}
	const modalRef = useRef<any>(null)
	const lastFocusedElementRef = useRef<any>(null)

	useEffect(() => {
		// Remember the last focused element before opening the modal
		lastFocusedElementRef.current = document.activeElement

		// Set focus to the modal or its first focusable child
		const focusableElements = modalRef?.current?.querySelectorAll(
			'a[href], button:not([disabled]), textarea, input, select',
		)

		// Trap focus within the modal
		const trapFocus = (event: any) => {
			if (!modalRef.current.contains(event.target)) {
				event.preventDefault()
				focusableElements[0].focus()
			}
		}

		document.addEventListener('focus', trapFocus, true)
		return () => {
			document.removeEventListener('focus', trapFocus, true)
		}
	}, [])

	// Restore focus when the modal closes
	useEffect(() => {
		return () => {
			if (lastFocusedElementRef.current) {
				lastFocusedElementRef.current.focus()
			}
		}
	}, [])

	return createPortal(
		<div
			className={cn(
				'fixed bottom-0 left-0 right-0 top-0 z-20 flex max-h-full w-full animate-fade justify-center overflow-y-auto overflow-x-hidden bg-black/80 p-2 md:inset-0',
				position === 'top' ? 'items-start' : 'items-center',
			)}
		>
			<div
				className={cn(
					'relative max-h-full w-full',
					size === 'md'
						? 'max-w-2xl'
						: size === 'lg'
							? 'max-w-4xl'
							: 'max-w-6xl',
				)}
				ref={modalRef}
				id="defaultModal"
				role="dialog"
				tabIndex={-1}
			>
				<div
					ref={ref}
					className={`relative rounded-lg border border-border shadow ${bgColor()} pb-6`}
				>
					<div className="flex items-start justify-between rounded-t px-6 pb-2 pt-6">
						{title && <Text as="h3">{title}</Text>}
						<button
							onClick={onClose}
							type="button"
							className="ml-auto inline-flex items-center self-center rounded-lg bg-transparent p-1.5 align-middle text-sm text-foreground duration-200 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
							data-modal-hide="defaultModal"
						>
							<Icon name="cross" size="md" />
							<span className="sr-only">{t('action_close')}</span>
						</button>
					</div>
					<div className="px-6">{children}</div>
				</div>
			</div>
		</div>,
		modalRoot,
	)
}
